import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_ToggleSection = _resolveComponent("ToggleSection")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tab-id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: `Session № ${_ctx.model?.id || '...'}`,
    "is-content-loading": _ctx.isLoading,
    tabs: _ctx.tabList
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, { "back-href": _ctx.backButtonUrl }, null, 8, ["back-href"])
    ]),
    default: _withCtx(() => [
      (!_ctx.isLoading && _ctx.model)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.selectedTabId === 'general')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_FieldValue, {
                    label: "ID",
                    value: String(_ctx.model.id)
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "UUID",
                    value: _ctx.model.uuid
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Device ID",
                    value: _ctx.model.deviceId
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Device Platform",
                    value: _ctx.model.platform
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Device Index",
                    value: String(_ctx.model.deviceIndex)
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Status",
                    value: _ctx.model.status
                  }, null, 8, ["value"]),
                  (_ctx.model.error)
                    ? (_openBlock(), _createBlock(_component_FieldValue, {
                        key: 0,
                        label: "Error",
                        value: _ctx.model.error
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_FieldValue, {
                    label: "Class",
                    value: _ctx.model.class
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Paid Avatars Quantity",
                    value: String(_ctx.model.paidImagesQuantity)
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Created",
                    value: _ctx.model.createdAt,
                    type: "datetime"
                  }, null, 8, ["value"]),
                  (_ctx.model.learningStartAt)
                    ? (_openBlock(), _createBlock(_component_FieldValue, {
                        key: 1,
                        label: "Learning Start",
                        type: "datetime",
                        value: _ctx.model.learningStartAt
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true),
                  (_ctx.model.learningFinishAt)
                    ? (_openBlock(), _createBlock(_component_FieldValue, {
                        key: 2,
                        label: "Learning Finish",
                        type: "datetime",
                        value: _ctx.model.learningFinishAt
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true),
                  (_ctx.model.learningMinutes)
                    ? (_openBlock(), _createBlock(_component_FieldValue, {
                        key: 3,
                        label: "Learning Time",
                        value: _ctx.model.learningMinutes + ' min.'
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.selectedTabId === 'images')
              ? (_openBlock(), _createBlock(_component_FieldValue, {
                  key: 1,
                  label: "User Images",
                  images: _ctx.images,
                  type: "gallery"
                }, null, 8, ["images"]))
              : _createCommentVNode("", true),
            (_ctx.selectedTabId === 'neural_love_model')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createVNode(_component_FieldValue, {
                    label: "Model ID",
                    value: _ctx.model.neuralLove.modelId
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Model Credits",
                    value: _ctx.model.neuralLove.modelCredits
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Model Status",
                    value: _ctx.model.neuralLove.modelStatus
                  }, null, 8, ["value"]),
                  (_ctx.model.neuralLove.modelError)
                    ? (_openBlock(), _createBlock(_component_FieldValue, {
                        key: 0,
                        label: "Model Error",
                        value: _ctx.model.neuralLove.modelError
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_FieldValue, {
                    label: "Model Updated",
                    type: "datetime",
                    value: _ctx.model.neuralLove.modelUpdatedAt
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Model JSON",
                    value: _ctx.model.neuralLove.modelJson,
                    type: "json"
                  }, null, 8, ["value"])
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.selectedTabId === 'neural_love_pack')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                  _createVNode(_component_FieldValue, {
                    label: "Pack ID",
                    value: _ctx.model.neuralLove.packId
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Pack Credits",
                    value: _ctx.model.neuralLove.packCredits
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Pack Status",
                    value: _ctx.model.neuralLove.packStatus
                  }, null, 8, ["value"]),
                  (_ctx.model.neuralLove.packError)
                    ? (_openBlock(), _createBlock(_component_FieldValue, {
                        key: 0,
                        label: "Pack Error",
                        value: _ctx.model.neuralLove.packError
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_FieldValue, {
                    label: "Pack Updated",
                    type: "datetime",
                    value: _ctx.model.neuralLove.packUpdatedAt
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Pack JSON",
                    value: _ctx.model.neuralLove.packJson,
                    type: "json"
                  }, null, 8, ["value"])
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.selectedTabId === 'adapty')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                  _createVNode(_component_FieldValue, {
                    label: "Transaction ID",
                    value: _ctx.model.adapty.transactionId
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "JSON",
                    value: _ctx.model.adapty.json,
                    type: "json"
                  }, null, 8, ["value"])
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.selectedTabId === 'packs')
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 5 }, _renderList(_ctx.model.packs, (item) => {
                  return (_openBlock(), _createBlock(_component_ToggleSection, {
                    key: item.style,
                    label: item.style
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FieldValue, {
                        type: "gallery",
                        images: item.images
                      }, null, 8, ["images"])
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tab-id", "title", "is-content-loading", "tabs"]))
}