
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import { isNotNullish, useResource } from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormFooter,
  FieldValue,
  TabType,
  ToggleSection,
} from "@tager/admin-ui";

import { getSessionsListUrl } from "@/modules/sessions/routes";

import { getSession } from "../services";

export default defineComponent({
  name: "SessionsFormView",
  components: {
    Page,
    FieldValue,
    FormFooter,
    ToggleSection,
  },
  setup() {
    const route = useRoute();
    const id = computed(() => route.params.id as string | undefined);

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource({
      fetchResource: () => {
        if (id.value) {
          return getSession(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Session",
    });

    onMounted(() => {
      fetchModel();
    });

    watch(id, fetchModel);

    const isLoading = computed<boolean>(() => isModelLoading.value);

    const tabList = computed<Array<TabType>>(() =>
      [
        { id: "general", label: "General" },
        { id: "images", label: "Images" },
        model.value?.adapty ? { id: "adapty", label: "Adapty" } : null,
        model.value?.neuralLove
          ? { id: "neural_love_model", label: "Neural.Love - Model" }
          : null,
        model.value?.neuralLove && model.value.neuralLove.packId
          ? { id: "neural_love_pack", label: "Neural.Love - Pack" }
          : null,
        model.value?.packs && model.value.packs.length
          ? { id: "packs", label: "Packs" }
          : null,
      ].filter(isNotNullish)
    );

    const selectedTabId = ref<string>(tabList.value[0].id);

    const images = computed<string[]>(() => {
      return model.value
        ? model.value.userImages.map((item) =>
            item.url.replace(
              "digitaloceanspaces.com",
              "cdn.digitaloceanspaces.com"
            )
          )
        : [];
    });

    return {
      isLoading,
      model,
      images,
      tabList,
      selectedTabId,
      backButtonUrl: getSessionsListUrl(),
    };
  },
  computed: {
    console: () => console,
  },
});
