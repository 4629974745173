
import { computed, defineComponent } from "vue";

import { HomeIcon, ViewListIcon } from "@tager/admin-ui";
import { MenuItemType, PageLayout } from "@tager/admin-layout";

import { getLinks } from "@/constants/links";

export default defineComponent({
  name: "BaseApp",
  components: { PageLayout },
  setup() {
    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      { id: "home", icon: HomeIcon, ...links.value.HOME },
      { id: "sessions", icon: ViewListIcon, ...links.value.SESSIONS_LIST },
      { id: "settings", icon: ViewListIcon, ...links.value.SETTINGS_LIST },
      {
        id: "commands",
        icon: ViewListIcon,
        text: "Commands",
        children: [links.value.COMMANDS, links.value.COMMANDS_LOGS],
      },
      { id: "admins", icon: ViewListIcon, ...links.value.ADMIN_ADMINS },
    ];

    return {
      sidebarMenuList,
    };
  },
});
