import { RouteRecordRaw } from "vue-router";
import { compile } from "path-to-regexp";

import { getLinks } from "@/constants/links";
import { ROUTE_PATHS } from "@/constants/paths";

import SessionsListView from "./SessionsListView";
import SessionsFormView from "./SessionsFormView";

export function getSessionsListUrl(): string {
  return ROUTE_PATHS.SESSIONS_LIST;
}

export function getSessionsViewUrl(id: number) {
  return compile(ROUTE_PATHS.SESSIONS_VIEW)({ id });
}

export const SESSIONS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SESSIONS_LIST,
  component: SessionsListView,
  name: "Sessions List",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().SESSIONS_LIST],
  },
};

export const SESSIONS_VIEW_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SESSIONS_VIEW,
  component: SessionsFormView,
  name: "Sessions View",
  meta: {
    getBreadcrumbs: () => [getLinks().HOME, getLinks().SESSIONS_LIST],
  },
};
