import { LinkType } from "@tager/admin-ui";
import { getAdminListUrl } from "@tager/admin-administrators";
import { getSettingItemListUrl } from "@tager/admin-settings";
import {
  getCommandsListUrl,
  getCronLogsUrl,
  getCommandsLogsUrl,
} from "@tager/admin-cron";

import { getSessionsListUrl } from "@/modules/sessions/routes";

type LinkKey =
  | "HOME"
  | "SESSIONS_LIST"
  | "ADMIN_ADMINS"
  | "SETTINGS_LIST"
  | "COMMANDS"
  | "COMMANDS_LOGS";

export const getLinks = (): Readonly<Record<LinkKey, LinkType>> => ({
  HOME: {
    url: "/",
    text: "Home",
  },
  SESSIONS_LIST: {
    url: getSessionsListUrl(),
    text: "Sessions",
  },
  SETTINGS_LIST: {
    url: getSettingItemListUrl(),
    text: "Settings",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Administrators",
  },
  COMMANDS: {
    url: getCommandsListUrl(),
    text: "Execute",
  },
  COMMANDS_LOGS: {
    url: getCommandsLogsUrl(),
    text: "Logs",
  },
});
