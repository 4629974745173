
import { defineComponent } from "vue";

import { DataTable, BaseButton, DeleteIcon } from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { useResourceDelete } from "@tager/admin-services";

import { deleteSession, getSessionsList } from "../services";
import { SessionInterface } from "../typings";

import { COLUMN_DEFS } from "./SessionsListView.helpers";

export default defineComponent({
  name: "SessionsListView",
  components: {
    Page,
    DataTable,
    BaseButton,
    DeleteIcon,
  },
  setup() {
    const {
      fetchEntityList: fetchData,
      isLoading: isDataLoading,
      rowData: data,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<SessionInterface>({
      fetchEntityList: (params) =>
        getSessionsList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Sessions List",
    });

    const { isDeleting, handleResourceDelete } = useResourceDelete({
      deleteResource: deleteSession,
      resourceName: "Session",
      onSuccess: fetchData,
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: data,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      handleResourceDelete,
      isDeleting,
    };
  },
});
