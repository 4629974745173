import { request, ResponseBody } from "@tager/admin-services";

import { SessionFullInterface, SessionInterface } from "./typings";

export interface CountData {
  count: number;
}

export function getSessionsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/sessions/count" });
}

export function getSessionsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<SessionInterface>>> {
  return request.get({ path: "/admin/sessions", params });
}

export function getSession(
  id: string | number
): Promise<ResponseBody<SessionFullInterface>> {
  return request.get({ path: `/admin/sessions/${id}` });
}

export function deleteSession(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/sessions/${id}` });
}
