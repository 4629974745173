
import { computed, defineComponent, onMounted } from "vue";

import { NavigationGrid, type NavigationGridItem } from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import { getLinks } from "@/constants/links";
import { getSessionsCount } from "@/modules/sessions";

export default defineComponent({
  name: "PageHome",
  components: { NavigationGrid },
  setup() {
    const [
      fetchSessionsCount,
      { data: sessionsCountData, status: sessionsCountDataStatus },
    ] = useResource({
      fetchResource: getSessionsCount,
      initialValue: null,
      resourceName: "Sessions count",
    });

    onMounted(() => {
      fetchSessionsCount();
    });

    const links = computed(() => getLinks());

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      return [
        {
          name: links.value.SESSIONS_LIST.text,
          url: links.value.SESSIONS_LIST.url,
          total: {
            value: sessionsCountData.value?.count ?? 0,
            status: sessionsCountDataStatus.value,
          },
          width: 50,
        },
        {
          name: links.value.SETTINGS_LIST.text,
          url: links.value.SETTINGS_LIST.url,
          width: 50,
        },
        {
          name: "Commands",
          linkList: [links.value.COMMANDS, links.value.COMMANDS_LOGS],
          width: 50,
        },
        {
          name: links.value.ADMIN_ADMINS.text,
          url: links.value.ADMIN_ADMINS.url,
          width: 50,
        },
      ];
    });

    return {
      navItemList,
    };
  },
});
