import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import {
  PAGE_COMMANDS_ROUTE,
  PAGE_COMMANDS_LOGS_ROUTE,
  PAGE_COMMANDS_LOG_DETAILS_ROUTE,
  PAGE_COMMANDS_DETAILS_ROUTE,
} from "@tager/admin-cron";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
} from "@tager/admin-administrators";
import {
  SETTINGS_ITEM_LIST_ROUTE,
  SETTINGS_ITEM_FORM_ROUTE,
} from "@tager/admin-settings";

import Home from "@/views/Home.vue";
import { SESSIONS_LIST_ROUTE, SESSIONS_VIEW_ROUTE } from "@/modules/sessions";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: (route, i18n) => [{ url: "/", text: i18n.t("admin:home") }],
  },
};

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,
    SESSIONS_LIST_ROUTE,
    SESSIONS_VIEW_ROUTE,
    SETTINGS_ITEM_LIST_ROUTE,
    SETTINGS_ITEM_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ADMIN_FORM_ROUTE,
    PAGE_COMMANDS_ROUTE,
    PAGE_COMMANDS_LOGS_ROUTE,
    PAGE_COMMANDS_LOG_DETAILS_ROUTE,
    PAGE_COMMANDS_DETAILS_ROUTE,
  ],
});
