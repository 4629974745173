import { ColumnDefinition } from "@tager/admin-ui";

import { getSessionsViewUrl } from "@/modules/sessions/routes";

import { SessionInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<SessionInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center" },
  },
  {
    id: 2,
    name: "UUID",
    field: "uuid",
    type: "name",
    format: ({ row }) => ({
      adminLink: {
        text: row.uuid,
        url: getSessionsViewUrl(row.id),
      },
      paramList: [
        {
          name: "Device ID",
          value: row.deviceId,
        },
        {
          name: "Device Platform",
          value: row.platform ? String(row.platform) : "Unknown",
        },
        {
          name: "Uploaded Images",
          value: String(row.imagesCount),
        },
        {
          name: "Paid Avatars",
          value: String(row.avatarsCount),
        },
      ],
    }),
  },
  {
    id: 3,
    name: "Neural.Love Credits",
    field: "neuralLoveCredits",
    style: { width: "150px", textAlign: "center" },
    headStyle: { width: "150px", whiteSpace: "nowrap", textAlign: "center" },
  },
  {
    id: 4,
    name: "Status",
    field: "status",
    style: { width: "150px", textAlign: "center" },
    headStyle: { width: "150px", whiteSpace: "nowrap", textAlign: "center" },
  },
  {
    id: 5,
    name: "Created",
    field: "createdAt",
    type: "datetime",
    style: { width: "185px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "185px", whiteSpace: "nowrap", textAlign: "center" },
  },
  {
    id: 6,
    name: "Completed",
    field: "completedAt",
    type: "datetime",
    style: { width: "185px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "185px", whiteSpace: "nowrap", textAlign: "center" },
  },
  {
    id: 7,
    name: "Learning Time",
    field: "learningMinutes",
    format: ({ row }) => {
      return row.learningMinutes ? row.learningMinutes + " min." : "-";
    },
    style: { width: "185px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "185px", whiteSpace: "nowrap", textAlign: "center" },
  },
  {
    id: 8,
    name: "",
    field: "actions",
    width: "40px",
  },
];
